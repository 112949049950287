.BoardListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.BoardListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.BoardListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.BoardListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.BoardListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.BoardListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BoardListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BoardListBoardNumberHeader {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BoardListBoardMacAddressHeader {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BoardListBoardNumber {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
}

.BoardListBoardMacAddress {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
}

.BoardMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.BoardMainToolbarLeft {
	font-size: 12px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.BoardMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.BoardMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.BoardMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.BoardMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BoardMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BoardInfoTitle {
	font-size: 20px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.BoardInfoItem {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.BoardInfoCommentUsername {
	font-size: 14px;
	font-weight: bold;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.BoardInfoCommentText {
	font-size: 14px;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}

.BoardInfoCommentDateTime {
	font-size: 14px;
	color: grey;
	padding: 2px 2px 2px 2px;
	margin: 0px 0px 0px 0px;
}



.BoardRideListHeaderRideID {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BoardRideListHeaderUploadedTime {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BoardRideListHeaderDuration {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BoardRideListItemRideID {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BoardRideListItemUploadedTimestamp {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BoardRideListItemDuration {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

