.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}

.RideMapTopContainer {
	width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.RideMapContainer1 {
	//width: 90vw;
	height: calc(100vh - 150px);
}

.RideMapSliderContainer {
	width: calc(100vw - 30px);
    margin-left: auto;
    margin-right: auto;
}

.RideMapContainer2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px 10px 0px;
	margin: 0px;
}

.RideMapCheckbox {
	font-size: 20px;
	padding: 2px;
	margin: 5px 0px 0px 0px;
	cursor: pointer;
	width: 30px;
	height: 18px;
}

.RideMapText {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

