.RideStatsTitle {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.RideStatsItem {
	font-size: 14px;
	padding: 2px;
	margin: 0px;
}

.RideToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.RideToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.RideToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.RideToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.RideToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.RideToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.RideToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.RideToolbarDisabledLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	color: grey;
}

.RideSettingsContainer1 {
	display: flex;
	flex-direction: column;
	padding: 0px;
	margin: 0px;
}

.RideSettingsRow {
	display: flex;
	flex-direction: row;
}

.RideSettingsContainer2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px 10px 0px;
	margin: 0px;
}

.RideSettingsCheckbox {
	font-size: 20px;
	padding: 2px;
	margin: 5px 0px 0px 0px;
	cursor: pointer;
	width: 30px;
	height: 18px;
}

.RideSettingsText {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.RideSettingsSelect {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.RideTableContainer {
	z-index: 1;
}

.RideChartCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
}

.RideDownloadText {
	font-size: 20px;
	padding: 5px;
}

.RideDownloadLink {
	color: black;
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.RideLogListChecboxContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px 10px 0px;
	margin: 0px;
}

.RideLogListChecbox {
	font-size: 20px;
	padding: 2px;
	margin: 5px 0px 0px 0px;
	cursor: pointer;
	width: 30px;
	height: 18px;
}

.RideLogListChecboxText {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.RideListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.RideListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.RideListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.RideListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.RideListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.RideListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.RideListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

