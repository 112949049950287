.user-picker-popup-content {
	background: rgb(255, 255, 255);
	width: 1200px;
	padding: 0px;
	margin: 0px;
}

.UserPickerPopupContainer {
	max-height: 80vh;
	overflow-y: auto;
}

.UserPickerEmailHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}

.UserPickerNameHeader {
	padding: 5px;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
}

.UserPickerEmail {
	padding: 5px;
	font-size: 16px;
	cursor: pointer;
}

.UserPickerName {
	padding: 5px;
	font-size: 16px;
	cursor: pointer;
}

