.BatteryListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.BatteryListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.BatteryListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.BatteryListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.BatteryListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.BatteryListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryListHeaderBatteryName {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BatteryListHeaderBatteryMacAddress {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BatteryListItemBatteryName {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
}

.BatteryListItemBatteryMacAddress {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
}

.BatteryMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.BatteryMainToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.BatteryMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.BatteryMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.BatteryMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.BatteryMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryInfoTitle {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.BatteryInfoItem {
	font-size: 14px;
	padding: 2px;
	margin: 0px;
}

.BatteryInfoCommentUsername {
	font-size: 14px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
}

.BatteryInfoCommentText {
	font-size: 14px;
	padding: 2px;
	margin: 0px;
}

.BatteryInfoCommentDateTime {
	font-size: 14px;
	color: grey;
	padding: 2px;
	margin: 0px;
}

.BatteryLogListHeaderBatteryLogID {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.BatteryLogListHeaderBatteryName {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.BatteryLogListHeaderOperation {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.BatteryLogListHeaderLogStarted {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.BatteryLogListHeaderUploadedTime {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.BatteryLogListHeaderDuration {
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 10vw;
}

.BatteryLogListItemBatteryLogID {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BatteryLogListItemBatteryName {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BatteryLogListItemOperation {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BatteryLogListItemLogStarted {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BatteryLogListItemUploadedTimestamp {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BatteryLogListItemDuration {
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
}

.BatteryLogStatsTitle {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.BatteryLogStatsItem {
	font-size: 14px;
	padding: 2px;
	margin: 0px;
}

.BatteryLogToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.BatteryLogToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.BatteryLogToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.BatteryLogToolbarContainer2 {
	display: flex;
	flex-direction: row;
	padding: 0px;
	margin: 0px;
}

.BatteryLogToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.BatteryLogToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryLogToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryLogSettingsContainer1 {
	display: flex;
	flex-direction: column;
	padding: 0px;
	margin: 0px;
}

.BatteryLogSettingsRow {
	display: flex;
	flex-direction: row;
}

.BatteryLogSettingsContainer2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 0px 10px 0px;
	margin: 0px;
}

.BatteryLogSettingsCheckbox {
	font-size: 20px;
	padding: 2px;
	margin: 5px 0px 0px 0px;
	cursor: pointer;
	width: 30px;
	height: 18px;
}

.BatteryLogSettingsText {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.BatteryLogSettingsSelect {
	font-size: 20px;
	padding: 2px;
	margin: 0px;
}

.BatteryLogTableContainer {
	z-index: 1;
}

.BatteryLogChartCustomTooltip {
	border-style: solid;
	border-width: thin;
	border-color: grey;
	background-color: white;
	padding: 15px 15px 15px 15px;
}

.BatteryLogDownloadText {
	font-size: 20px;
	padding: 5px;
}

.BatteryLogDownloadLink {
	color: black;
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
}

.BatteryLogAllToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.BatteryLogAllToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.BatteryLogAllToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.BatteryLogAllToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.BatteryLogAllToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.BatteryLogAllToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.BatteryLogAllToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

