.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}

.MapContainer {
	//width: 90vw;
	height: calc(100vh - 120px);
}

.leaflet-grab {
	cursor: pointer;
}

.GeofenceListToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.GeofenceListToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.GeofenceListToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.GeofenceListToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.GeofenceListToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.GeofenceListToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.GeofenceListToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.GeofenceListHeaderGeofenceName {
    display: table-cell;
	font-size: 20px;
	font-weight: bold;
    padding: 5px;
    width: 20vw;
}

.GeofenceListItemGeofenceName {
    display: table-cell;
	color: black;
	font-size: 20px;
    padding: 5px;
	cursor: pointer;
    width: 20vw;
}

.GeofenceMainToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.GeofenceMainToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.GeofenceMainToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.GeofenceMainToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.GeofenceMainToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.GeofenceMainToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.GeofenceMainToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.GeofenceMapToolbarContainer1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 23px;
	background-color: #dddddd;
	align-items: center;
	justify-content: center;
	left: 0;
	bottom: 0;
}

.GeofenceMapToolbarLeft {
	font-size: 12px;
	padding: 2px;
	margin: 0px 0px 0px 10px;
	min-width: 10px;
	text-align: left;
	margin-left: 10px;
	padding: 0px;
	width: fit-content;
}

.GeofenceMapToolbarRight {
	margin-right: 10px;
	justify-self: end;
}

.GeofenceMapToolbarContainer2 {
	display: flex;
	flex-direction: row;
}

.GeofenceMapToolbarText {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
}

.GeofenceMapToolbarLink {
	font-size: 12px;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}

.GeofenceMapToolbarSelectedLink {
	font-size: 12px;
	font-weight: bold;
	padding: 2px;
	margin: 0px;
	cursor: pointer;
}


