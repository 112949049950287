.SelectCompanyTopContainer {
}

.SelectCompanyTitle {
	font-size: 20px;
	font-weight: bold;
}

.SelectCompanyName {
	font-size: 20px;
	cursor: pointer; 
}

